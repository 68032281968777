import React, {Component} from "react";
import './style.scss';
import Image from "../Image";
import ScrollAnimation from 'react-animate-on-scroll';

const paragraphs = [
    {
        title: "THẤU HIỂU",
        content: "Chúng tôi đặt trọng tâm trong việc lắng nghe và tìm hiểu sâu nhu cầu khách hàng, áp dụng tư duy customer-driven trong Digital."
    },
    {
        title: "ĐỒNG HÀNH",
        content: "Không chỉ là đội ngũ thực thi dự án, chúng tôi đồng hành và hỗ trợ đưa ra các giải pháp để cùng đối tác xây dựng phương án tốt nhất cho mọi vấn đề."
    },
    {
        title: "LINH HOẠT",
        content: "Với đội ngũ trẻ và nhiệt huyết, chúng tôi chú trọng tính linh hoạt thay đổi trong sáng tạo và linh động hỗ trợ trong mọi khung giờ làm việc của đối tác.\n"
    }
];

const image = "/section3.png"
class MissionMH extends Component {
    render() {
        return (
            <div className={"mission-container"}>
                <div className={"mission-title"}>
                    <h3>Đồng hành cùng bạn trong mục tiêu <br/>"Chuyển đổi số".</h3>
                </div>
                <div className={"mission-paragraph"}>
                    {
                        paragraphs.map((paragraph,index) =>{
                            return (
                                <ScrollAnimation
                                    className={'paragraph-wrapper'}
                                    key={`mh-session-${index}`}
                                    animateIn={"fadeInLeft"}
                                    animateOnce={true}
                                    duration={1.5 + index * 0.7}
                                >
                                    <div  className={"paragraph"}>
                                        <div className={"title-paragraph"}>
                                            <p>{paragraph.title}</p>
                                        </div>
                                        <div className={"content-paragraph"}>
                                            <p>{paragraph.content}</p>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            );
                        })
                    }
                </div>

                <Image image={image} width={"96rem"} class={"image-mission-mini"}/>
            </div>
        );
    }
}

export default MissionMH;