import React, {Component} from "react";
import './toastr.css';
import './animate.css';
import {ToastContainer} from "react-toastr";


class AlertComponent extends Component {
    constructor(props){
        super(props);

        this.success = this.success.bind(this);
        this.error = this.error.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
    }
    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    success(text,title,e){
        this.toast.success(text,title,e);
    }
    error(text,title,e){
        this.toast.error(text,title,e);
    }
    warning(text,title,e){
        this.toast.warning(text,title,e);
    }
    render() {
        return (
            <div className={"alert-container"} style={{
                fontSize: "1.6rem"
            }}>
                <ToastContainer
                    ref={ref => {this.toast = ref}}
                    className={"toast-bottom-center"}
                />
            </div>
        );
    }
}

export default AlertComponent;