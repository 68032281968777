import React,{Component} from "react";
import './style.scss';

class SideDrawer extends Component{
    constructor(props){
       super(props);
       this.linkToService = this.linkToService.bind(this);
    }


    linkToService() {
        let top = document.getElementById("service-id").getBoundingClientRect().top;
        let position = top + window.scrollY;
        window.scrollTo({
            top: position,
            behavior: "smooth"
        });
        // eslint-disable-next-line no-unused-expressions
        this.props.click();
    };
    linkToMission = () => {

        let top = document.getElementById("mission-id").getBoundingClientRect().top;
        window.scrollTo({
            top: window.scrollY + top,
            behavior: "smooth"
        })
        // eslint-disable-next-line no-unused-expressions
        this.props.click();
    };
    linkToPartner = () => {
        let top = document.getElementById("partner-id").getBoundingClientRect().top;
        let position = top + window.scrollY;
        window.scrollTo({
            top: position,
            behavior: "smooth"
        })
        // eslint-disable-next-line no-unused-expressions
        this.props.click();
    };
    linkToContact = () => {
        let top = document.getElementById("contact-id").getBoundingClientRect().top;
        let position = top + window.scrollY;
        window.scrollTo({
            top: position,
            behavior: "smooth"
        })
        // eslint-disable-next-line no-unused-expressions
        this.props.click();
    };

    render() {
        return(
            <div className={this.props.show ? "side-drawer-container open": "side-drawer-container"} >
                <div onClick={ this.linkToService }>
                    Dịch vụ
                </div>
                <div onClick={this.linkToMission}>
                    Sứ mệnh
                </div>
                <div onClick={this.linkToPartner}>
                    Đối tác
                </div>
                <div onClick={this.linkToContact}>
                    Liên hệ
                </div>
            </div>
        );
    }
}

export default SideDrawer;