import React,{Component} from "react";
import './style.scss'
class FlatButton extends Component{
    render() {
        return(
            <div className={`flat-button ${this.props.class}`} onClick={this.props.click}>
                <a href={this.props.link} >{this.props.value}</a>
            </div>
        );
    }
}
export  default FlatButton;