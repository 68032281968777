import React, { Component } from "react";
import "./style.scss";
import Image from "../Image";
import ScrollAnimation from "react-animate-on-scroll";

const list = [
  {
    serviceType: "Dịch vụ quảng cáo",
    titleService: "TRÊN CÁC KÊNH TRUYỀN THÔNG UY TÍN",
    image: "media-4-sec2.png",
    services: [
      "Noron.vn - Mạng xã hội hỏi đáp lớn nhất Việt Nam",
      "Các nền tảng lớn của tập đoàn Viettel, Truyền hình An Viên, Truyền hình Quân đội,...",
    ],
    styleImg: {
      width: "40rem",
    },
  },
  {
    serviceType: "Dịch vụ sản xuất",
    titleService: "VIDEO 2D MOTION GRAPHIC",
    image: "media-1-sec2.png",
    services: [
      "Giới thiệu doanh nghiệp",
      "Giới thiệu dịch vụ",
      "Giới thiệu sản phẩm",
    ],
    styleImg: {
      width: "40rem",
      marginRight: "-30px",
    },
  },
  {
    serviceType: "Dịch vụ sản xuất",
    titleService: "2D GRAPHIC DESIGN",
    image: "media-2-sec2.png",
    services: [
      "Thiết kế bao bì sản phẩm",
      "Thiết kế Banner, Poster,...",
      "Thiết kế logo, nhận diện thương hiệu",
    ],
    styleImg: {
      marginLeft: "-50px",
    },
  },
  {
    serviceType: "Dịch vụ",
    titleService: "QUAY PHIM, CHỤP ẢNH",
    image: "media-3-sec2.png",
    services: [
      "Quay/chụp sự kiện",
      "Quay/chụp giới thiệu Doanh nghiệp, sản phẩm,...",
    ],
    styleImg: {
      marginRight: "-35px",
    },
  },
];

class ServiceMH extends Component {
  render() {
    return (
      <div className={"service-container"}>
        <div className={"service-title"}>
          <h3>Hân hạnh cung cấp</h3>
        </div>
        {list.map((service, index) => {
          return (
            <div
              key={`sv-mh-${index}`}
              className={"service"}
              style={{
                flexDirection: index % 2 === 0 ? "row" : "row-reverse",
              }}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                offset={10}
                direction={2}
                animateOnce={true}
              >
                <div className={"service-image"}>
                  <Image
                    image={service.image}
                    width={"50rem"}
                    class={"image-mini"}
                    style={service.styleImg}
                  />
                </div>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInRight"
                offset={10}
                animateOnce={true}
              >
                <div className={"content-service"}>
                  <p>{service.serviceType}</p>
                  <h3>{service.titleService}</h3>
                  <ul>
                    {service.services.map((service, index) => {
                      return <li key={`option-sv-${index}`}>{service}</li>;
                    })}
                  </ul>
                </div>
              </ScrollAnimation>
            </div>
          );
        })}
      </div>
    );
  }
}

export default ServiceMH;
