import React, { Component } from "react";
import "./style.scss";
import Image from "../Image";

const products = [
  {
    image: "product-1.png",
    text: "10+ Dự án",
    link: "https://drive.google.com/drive/folders/1mkduODOBgYLNEBiH1m63xws8LiwzbLoH",
  },
  {
    image: "product-2.png",
    text: "5+ Dự án",
    link: "https://drive.google.com/drive/folders/1taGOC5flJ1LBd2EV1J82DOwAdVFkQFQf",
  },
  {
    image: "",
    text: "50+ Dự án cùng nhiều khách hàng khác",
    link: "https://drive.google.com/file/d/1W3uFoRMmynWEUuFDI-sO0SfKauRRmkrC/view",
  },
];

class Partner extends Component {
  render() {
    return (
      <div className={"partner-container"}>
        <h3>Đối tác của MH MEDIA</h3>
        <div className={"product-partner"}>
          {products.map((product, index) => {
            return (
              <div key={index} className={"product"}>
                <a
                  href={product.link}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  <div className="image-product">
                    <Image image={product.image} width={"20rem"} />
                  </div>
                  <div className={"text-product"}>{product.text}</div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Partner;
