import React,{Component} from "react";
import Image from "../Image";
import './style.scss';

const efficiency_content = {
    image: 'section4.png',
    title: 'Tối ưu hiệu quả trên từng đồng ngân sách chi tiêu',
    desc: 'Chúng tôi cung cấp dịch vụ chuyên nghiệp, sản phẩm đầu ra chất lượng cao với mức chi phí nhỏ hơn gấp nhiều lần so với việc doanh nghiệp sử dụng inhouse production.'
}
class Efficiency extends Component {
    render() {
        return (
            <div className={"efficiency-container"}>
                <div className={"efficiency-title"}>
                    <h3>{efficiency_content.title}</h3>
                </div>
                <div className={"content-efficiency"}>
                    <div className={"image-efficiency"}>
                        <Image image={efficiency_content.image} width={"60rem"} class={"image-efficiency-mini"}/>
                    </div>
                    <div className={"text-efficiency"}>
                        <div>
                        <p>{efficiency_content.desc}</p>
                    </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Efficiency;