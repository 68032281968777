import React, {Component} from "react";
import './style.scss';
import Header from "../../components/Header";
import IntroduceMH from "../../components/Introduce";
import Wrapper from "../../components/Wrapper";
import ServiceMH from "../../components/ServiceMH";
import MissionMH from "../../components/MissionMH";
import Efficiency from "../../components/Efficiency";
import Partner from "../../components/Partner";
import Footer from "../../components/Footer";

import 'animate.css/animate.compat.css';

class HomePageContainer extends Component {
    constructor() {
        super();
        this.state = {
            scrolled: false
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 30)
                this.setState({
                    scrolled: true
                })
            else
                this.setState({
                    scrolled: false
                })
        })
    }
    showToast = () =>{

    }


render() {
        return (
            <div className={"container"}>
                <div className={"header"} style={this.state.scrolled ? {
                    background: "rgba(0,0,0,0.7)",
                    height: "7rem"
                } : {}}>
                    <Wrapper content={<Header scrolled={this.state.scrolled}/>}/>
                </div>
                <div className={"introduction"} id={"introduce-id"}>
                    <Wrapper content={<IntroduceMH/>}
                            color={"linear-gradient(to left,rgb(13 172 250), rgb(22 104 250))"}
                             class={"height-bg-introduce"} />
                </div>
                <div className={"service"} id={"service-id"}>
                    <Wrapper content={<ServiceMH/>} color={"#fff"}/>
                </div>
                <div className={"mission"} id={"mission-id"}>
                    <Wrapper content={<MissionMH/>} color={"#e7edfc"} />
                </div>
                <div className={"efficiency"}>
                    <Wrapper content={<Efficiency/>} color={"#fff"}/>
                </div>
                <div className={"partner"} id={"partner-id"}>
                    <Wrapper content={<Partner/>} image={"/background-sec5.png"}  class={"height-bg-partner"}/>
                </div>
                <div className={"footer"} id={"contact-id"}>
                    <Wrapper content={<Footer click={this.showToast}/>} color={"#171717"}  class={"height-footer"}/>
                </div>
            </div>
        );
    }
}

export default HomePageContainer;