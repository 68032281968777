import React, { Component } from "react";
import "./style.scss";

class Image extends Component {
  render() {
    return (
      <img
        className={this.props.class}
        src={process.env.PUBLIC_URL + this.props.image}
        style={{
          width: this.props.width,
          ...(this.props.style || {}),
        }}
        alt={this.props.altText}
      />
    );
  }
}

export default Image;
