import React,{Component} from "react";
import './style.scss';

class BackDrop extends Component{
    render() {
        return(
            <div className={"backdrop"} onClick={this.props.click} />
        );
    }
}
export default BackDrop;