import React, { Component } from "react";
import "./style.scss";
import FlatButton from "../FlatButton";

const name_button = "Nhận tư vấn miễn phí";
const intro_content = {
  imageName: "artboard.png",
  title: "DỊCH VỤ QUẢNG CÁO VÀ SẢN XUẤT ẤN PHẨM TRUYỀN THÔNG",
  desc: "Là đối tác chiến lược của Viettel, Truyền hình An Viên, Truyền hình Quân đội,...  MH Media cung cấp dịch vụ Quảng cáo với hình thức đa dạng, trên các kênh truyền thông lớn như TV360, Myclip, Viettel TV... và mạng xã hội hỏi đáp lớn nhất Việt Nam -  Noron.vn, đồng thời sản xuất các ấn phẩm truyền thông sáng tạo và chuyên nghiệp.",
};

class IntroduceMH extends Component {
  render() {
    return (
      <div className={"intro-container"}>
        <div className={"image-intro"}>
          <img
            src={process.env.PUBLIC_URL + intro_content.imageName}
            alt={"imageIntroduce"}
          />
        </div>

        <div className={"content-intro"}>
          <div className={"title-intro"}>
            <h3>{intro_content.title}</h3>
          </div>
          <div className={"desc-intro"}>
            <p>{intro_content.desc}</p>
          </div>
          <div
            className={"button-join"}
            onClick={() => {
              let top = document
                .getElementById("footer-id")
                .getBoundingClientRect().top;
              window.scrollTo({
                top: top,
                behavior: "smooth",
              });
            }}
          >
            <FlatButton value={name_button} />
          </div>
        </div>
      </div>
    );
  }
}

export default IntroduceMH;
