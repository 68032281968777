import React, {Component} from "react";
import './style.scss';

class Wrapper extends Component {
    render() {
        return (
            <div className="wrapper">
                <div className={"background " + this.props.class}
                     style={
                         this.props.image ? {
                             background: `url(${process.env.PUBLIC_URL + this.props.image})`,
                             backgroundRepeat: "no-repeat",
                             backgroundSize:"cover"
                         } : {
                             background: `${this.props.color}`
                         }
                     }
                />
                <div className="content-wrap">
                    {this.props.content}
                </div>
            </div>
        );
    }
}

export default Wrapper;