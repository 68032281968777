import React, { Component } from "react";
import "./style.scss";
import Image from "../Image";
import FlatButton from "../FlatButton";
import ScrollAnimation from "react-animate-on-scroll";
import axios from "axios";
import AlertComponent from "../Alert";

const company = {
  name: "CÔNG TY CỔ PHẦN CÔNG NGHỆ TRUYỀN THÔNG MH MEDIA",
  email: "contact@mh.media",
  sdt: "0961 670 211 (Ms. Quỳnh)",
};
const desc = {
  title: "ĐỂ LẠI LỜI NHẮN CHO CHÚNG TÔI",
  content:
    "Chúng tôi nỗ lực không ngừng trong việc sáng tạo và ứng dụng những tri thức cập nhật nhất để tạo ra các" +
    " sản phẩm đáp ứng tốt nhu cầu của mọi khách hàng. Liên hệ ngay với chúng tôi để nhận được tư vấn cho nhu cầu của bạn:",
};

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      need: "",
      code: 0,
      submit: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.showAlert = this.showAlert.bind(this);
  }

  showAlert() {
    this.setState({
      showAlert: true,
    });
  }

  closeAlert() {
    this.setState({
      showAlert: false,
    });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleSubmit = (event) => {
    if (this.state.name === "" || (this.state.email === "" && this.state.phone === "")  || this.state.need === "") {
      this.alert.warning("Vui lòng điền đầy đủ thông tin.", "", {
        closeButton: true,
      });
      return;
    }

    let regPhone = /(^0)+([0-9]{9})/;
    let regEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!regPhone.test(this.state.phone) && !regEmail.test(this.state.email)) {
      this.alert.warning(
        "Vui lòng kiểm tra lại số điện thoại hoặc email.",
        "",
        {
          closeButton: true,
        }
      );
      return;
    }

    const content = {
      title: "Khách hàng cần nhận tư vấn từ MH MEDIA",
      content: `<!doctype html> <html>
                       <body>
                       <h3>Gửi công ty MH MEDIA.</h3>
                       <p><b>Tôi là: </b>${this.state.name}</p>
                       <p>Tôi đã được biết tới các dịch vụ của công ty bạn qua website <b>mh.media</b></p>
                       <p><b>Hiện tại tôi đang có nhu cầu:</b> <i>${this.state.need}.</i> </p>
                       <p>Muốn được công ty tư vấn thêm. Rất mong bạn sớm liên hệ lại với tôi!</p>
                       <br/>
                       <h4>Thông tin liên lạc:</h4>
                       <p><b>Email:</b> ${this.state.email}</p>
                       <p><b>Phone: </b>${this.state.phone}</p>
                       <hr/>
                       <span>Mail được gửi từ mh.media.</span></body></html>`,
      address: "contact@mh.media",
    };

    axios
      .post("https://login.noron.vn/api/v3/mhmedia/email/feedback", content)
      .then((response) => {
        switch (response.data.code) {
          case 0:
            this.setState({
              code: 0,
            });
            this.alert.success(
              `Công ty chúng tôi sẽ liên hệ với bạn sớm nhất có thể!`,
              "",
              {
                closeButton: true,
              }
            );
            break;
          case 400:
          case 500:
          case 404:
          default:
            this.setState({
              code: 400,
            });
            this.alert.error(
              `Hệ thống đang bận. Chúng tôi xin lỗi về sự bất tiện này!`,
              "",
              {
                closeButton: true,
              }
            );
            break;
        }
      });

    event.preventDefault();
  };

  render() {
    return (
      <div>
        <AlertComponent
          onRef={(ref) => {
            this.alert = ref;
          }}
        />
        <div className={"footer-container"} id={"footer-id"}>
          <ScrollAnimation
            animateIn="fadeInLeft"
            offset={10}
            direction={2}
            animateOnce={true}
          >
            <div className={"contact-box"}>
              <div className={"information"}>
                <div className={"logo"}>
                  <Image
                    image={"logo.png"}
                    width={"13rem"}
                    class={"logo-mini-footer"}
                  />
                </div>
                <div className={"address"}>
                  <span>{company.name}</span>
                  <p>
                    Email: {company.email}
                    <br />
                    SĐT: {company.sdt}
                  </p>
                </div>
              </div>
              <div className={"desc"}>
                <h6>{desc.title}</h6>
                <p>{desc.content}</p>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="fadeInRight"
            offset={10}
            direction={2}
            animateOnce={true}
          >
            <div className={"form-box"}>
              <input
                type={"text"}
                name={"name"}
                placeholder={"Họ và tên"}
                onChange={this.handleChange}
              />
              <input
                type={"text"}
                name={"email"}
                placeholder={"Email"}
                onChange={this.handleChange}
              />
              <input
                type={"text"}
                name={"phone"}
                placeholder={"Số điện thoại"}
                onChange={this.handleChange}
              />
              <textarea
                name={"need"}
                onChange={this.handleChange}
                placeholder={
                  "Nhu cầu của bạn là gì? Thiết kế ảnh/video? Khoảng ngân sách bao nhiêu?..."
                }
              />
              <FlatButton
                click={this.handleSubmit}
                value={"Nhận tư vấn miễn phí"}
              />
            </div>
          </ScrollAnimation>
        </div>
      </div>
    );
  }
}

export default Footer;
