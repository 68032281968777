import React, {Component} from "react";
import Image from "../Image";
import smoothscroll from 'smoothscroll-polyfill';
import './style.scss';
import BackDrop from "../Backdrop";
import SideDrawer from "../SideDrawer";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openMenu: false
        }
    }

    componentDidMount() {
        smoothscroll.polyfill();
    }

    openMenu = () => {
        this.setState({
            openMenu: !this.state.openMenu
        })
    };
    closeMenu = () => {
        this.setState({
            openMenu: false
        })
    };
    linkToIntroduce = () =>{
        let top = document.getElementById("introduce-id").getBoundingClientRect().top;
        let position = top + window.scrollY;
        window.scrollTo({
            top: position,
            behavior: "smooth"
        });
    };
    linkToService = () => {
        let top = document.getElementById("service-id").getBoundingClientRect().top;
        let position = top + window.scrollY;
        window.scrollTo({
            top: position,
            behavior: "smooth"
        });
    };
    linkToMission = () => {

        let top = document.getElementById("mission-id").getBoundingClientRect().top;
        window.scrollTo({
            top: window.scrollY + top,
            behavior: "smooth"
        })
    };
    linkToPartner = () => {
        let top = document.getElementById("partner-id").getBoundingClientRect().top;
        let position = top + window.scrollY;
        window.scrollTo({
            top: position,
            behavior: "smooth"
        })
    };
    linkToContact = () => {
        let top = document.getElementById("contact-id").getBoundingClientRect().top;
        let position = top + window.scrollY;
        window.scrollTo({
            top: position,
            behavior: "smooth"
        })
    };


    render() {
        let sideDrawer = <SideDrawer click={this.closeMenu} show={this.state.openMenu}/>;
        let backDrop;
        if (this.state.openMenu) {
            backDrop = <BackDrop click={this.closeMenu}/>;
        }
        return (
            <div className={"header-container"}>
                <div className={this.props.scrolled ? "nav-header1 nav-header-mini" : "nav-header1"}>
                    <div
                        className="logo-mh"
                        onClick={this.linkToIntroduce}
                    >
                        <Image
                            image={"/logo.png"}
                            class={this.props.scrolled ? "" : "logo-big"}
                        />
                    </div>
                    {sideDrawer}
                    {backDrop}
                    <div className={"logo-menu"} onClick={this.openMenu}>
                        <Image image={"menu-icon.png"} width={"3rem"}/>
                    </div>
                    <div className="link-header">
                        <div onClick={this.linkToService}>
                            Dịch vụ
                        </div>
                        <div onClick={this.linkToMission}>
                            Sứ mệnh
                        </div>
                        <div onClick={this.linkToPartner}>
                            Đối tác
                        </div>
                        <div onClick={this.linkToContact}>
                            Liên hệ
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;